<template>
  <v-container fluid tag="section">
    <v-tabs v-model="step" center-active grow>
      <v-tab href="#step1">Wettkampfeinstellungen</v-tab>
      <v-tab href="#step2">Ergebnisse</v-tab>
      <v-tab href="#step3">Zwischenstand</v-tab>
      <v-tab href="#step4">Urkunden</v-tab>

      <v-tab-item id="step1"><h1>Wettkampfeinstellungen</h1><allgemein :id="id" :e="e" :r="ResultFind" :df="DisciplineFind" /></v-tab-item>
      <v-tab-item id="step2"><h1>Ergebnisse</h1><ergebnisse :id="id" :e="e" :r="ResultFind" :df="DisciplineFind" /></v-tab-item>
      <v-tab-item id="step3"><h1>Zwischenstand</h1><zwischenstand :id="id" :e="e" :r="ResultFind" :df="DisciplineFind" /></v-tab-item>
      <v-tab-item id="step4"><h1>Urkunden</h1><urkunden :id="id" :e="e" :r="ResultFind" /></v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, updateQuery } from '@/plugins/graphql'
import { query, resultquery } from '@/views/components/stb_ltf_2024/content/input/query'
import Vue from 'vue'

export default {
  name: 'liveinput',

  components: {
    Allgemein: () => import('./input/allgemein'),
    Ergebnisse: () => import('./input/ergebnisse'),
    Zwischenstand: () => import('./input/zwischenstand'),
    Urkunden: () => import('./input/urkunden')
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    step: 'step1',
    Event: {},
    DisciplineFind: [],
    ResultFind: []
  }),

  computed: {
    e () {
      return {
        ...this.Event,
        teams: (this.Event?.teams || [])/* .map(t => ({
          ...t,
          athletes: t.athletes?.map(a => ({
            ...a,
            results: this.ResultFind.filter(r => r.teamId === t.identifiers.find(id => id.name === 'gymnet.teamId')?.value && r._person === a.person._id)
          }))
        })) */
      }
    }
  },

  apollo: {
    Event: {
      query: gql`query($id: UUID!) {
        Event(id: $id) { ${query} }
      }`,
      variables () {
        return {
          id: this.id
        }
      }
    },
    DisciplineFind: {
      query: gql`query {
        DisciplineFind { _id name shortName regular }
      }`,
      fetchPolicy: 'cache-first',
      subscribeToMore: {
        document: gql`subscription {
          DisciplineCreate { _id name shortName regular }
        }`,
        updateQuery: updateQuery('DisciplineFind', 'DisciplineCreate')
      }
    },
    ResultFind: {
      query: gql`query($event: UUID!) {
        ResultFind(event: $event) { ${resultquery} }
      }`,
      variables () {
        return {
          event: this.id
        }
      },
      subscribeToMore: {
        document: gql`subscription($event: UUID!) {
          ResultCreate(event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            event: this.id
          }
        },
        updateQuery: updateQuery('ResultFind', 'ResultCreate')
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`subscription($id: UUID!) {
          EventUpdate(id: $id) { ${query} }
        }`,
        variables () {
          return {
            id: this.id
          }
        }
      },
      DisciplineUpdate: {
        query: gql`subscription {
          DisciplineUpdate { _id name shortName regular }
        }`
      },
      DiscliplineDelete: {
        query: gql`subscription {
          DisciplineDelete
        }`,
        result (id) {
          deleteQuery('DisciplineFind', 'DisciplineDelete', this.DisciplineFind, id)
        }
      },
      ResultUpdate: {
        query: gql`subscription($event: UUID!) {
          ResultUpdate(event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            event: this.id
          }
        },
        result ({ data }) {
          Vue.set(this.ResultFind, this.ResultFind.findIndex(r => r._id === data.ResultUpdate._id), data.ResultUpdate)
        }
      },
      ResultDelete: {
        query: gql`subscription($event: UUID) {
          ResultDelete(event: $event)
        }`,
        variables () {
          return {
            event: this.id
          }
        },
        result (id) {
          deleteQuery('ResultFind', 'ResultDelete', this.ResultFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>
h1 {
  background: rgb(80, 80, 80);
  color: white;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
}
</style>
